import React from 'react';
import { ImageTitledCard } from '../';
import './Projects.css';

let content = {
    'theWall': {
        'desc': `<h2>The Wall</h2>
            An app to motivate long-term progress and graphically track them across various skills.
            <br> Works on Windows, Linux and Mac.`,
        'img': 'the_wall_logo',
        'tags': ['Desktop app', 'Cross-platform', 'JavaScript', 'ElectronJS', 'Sequelize DB'],
        'sourceCode' : 'https://github.com/CatalanCabbage/the-wall',
        'cta': '<a href="https://github.com/CatalanCabbage/the-wall#the-wall" target=”_blank”>Download app</a>'
    },
    'noHello': {
        'desc': `<h2>NoHello</h2>
            A visual webpage to ask your coworkers (nicely) to skip the niceties and communicate better, asynchronously.
            <br>Customizable. Add any name to the URL!`,
        'img': 'nohello_logo',
        'tags': ['Website', 'JavaScript', 'ReactJS'],
        'sourceCode' : 'private',
        'cta': '<a href="https://dvsj.in/hello-from-my-website" target=”_blank”>Check the website out</a>'
    },
    'electronVLog': {
        'desc': `<h2>Electron vLog</h2>
            The zero-effort way to take video recordings, screenshots and timelapse images of your Electron app.`,
        'img': 'electron_vlog_logo',
        'tags': ['Desktop app extension', 'JavaScript', 'ElectronJS', 'WebRTC'],
        'sourceCode' : 'https://github.com/CatalanCabbage/electron-vlog'
    },
    'zeWallet': {
        'desc': `<h2>The Wallet</h2>
            Store bookmarks and excerpts with ease.
            <br>Maintain your own user account, categorize by tags, auto-populate metadata.`,
        'img': 'ze_wallet_logo',
        'tags': ['Webapp', 'JavaScript', 'Svelte', 'Supabase', 'Serverless'],
        'sourceCode' : 'private',
        'cta': '<a href="https://zewallet.netlify.app/" target=”_blank”>Sign up now!</a>'
    },
    'checkinExtension': {
        'desc': `<h2>Zoho Check-in Chrome Extension</h2>
        Chromium browser extension to view status and check-in/check-out in Zoho People from any tab in the browser.`,
        'img': 'checkin_extension_logo',
        'tags': ['Chrome extension', 'JavaScript'],
        'sourceCode' : 'https://github.com/CatalanCabbage/zpeople-attendance-chrome-extn'
    },
    'writeRight': {
        'desc': `<h2>WriteRight</h2>
        An android app: select text, tap the rewrite menu option to get a better version of the text using LLMs.
        <br>Built with Ionic and then Flutter - ultimately discontinued.`,
        'img': 'write_right_logo',
        'tags': ['Android app', 'Kotlin', 'Dart', 'Flutter', 'Ionic', 'OpenAI'],
        'sourceCode' : 'private'
    },
    'khartoum': {
        'desc': `<h2>Khartoum</h2>
        An AI-native TUI desktop app to manage your tasks using natural language with changelogs for each task. Uses a state machine.`,
        'img': 'khartoum_logo',
        'tags': ['Desktop app', 'Python', 'Text UI', 'state machine', 'Gemini'],
        'sourceCode' : 'private'
    },
    'khartoumV2': {
        'desc': `<h2>Khartoum v2</h2>
        A Whatsapp-first agentic AI assistant to manage tasks, grocery lists, reminders, and anything you can imagine.
        <br>Just text him!`,
        'img': 'khartoum_v2_logo',
        'tags': ['Whatsapp business app', 'Agentic AI', 'Python', 'FastAPI', 'Gemini'],
        'sourceCode' : 'private'
    },
    'plusOrMinusOne': {
        'desc': `<h2>Plus or Minus One</h2>
        A Q&A game to test your grasp of numbers. Questions range from unit conversions to country populations to tomato prices.
        <br> Configurable tolerances, uses browser storage to remember your score.`,
        'img': 'plus_or_minus_one_logo',
        'tags': ['Website', 'JavaScript', 'Svelte'],
        'sourceCode' : 'private'
    }
}
function Projects() {
    return (
        <div id="projects">
            <section id="projects-container">
                <div className="project">
                    <ImageTitledCard name="zeWallet" img={content.zeWallet.img} desc={content.zeWallet.desc} theme="projects" tags={content.zeWallet.tags} sourceCode={content.zeWallet.sourceCode} cta={content.zeWallet.cta}/>
                </div>
                <div className="project">
                    <ImageTitledCard name="noHello" img={content.noHello.img} desc={content.noHello.desc} theme="projects" tags={content.noHello.tags} sourceCode={content.noHello.sourceCode} cta={content.noHello.cta}/>
                </div>
                <div className="project">
                    <ImageTitledCard name="theWall" img={content.theWall.img} desc={content.theWall.desc} theme="projects" tags={content.theWall.tags} sourceCode={content.theWall.sourceCode} cta={content.theWall.cta}/>
                </div>
                <div className="project">
                    <ImageTitledCard name="checkinExtension" img={content.checkinExtension.img} desc={content.checkinExtension.desc} theme="projects" tags={content.checkinExtension.tags} sourceCode={content.checkinExtension.sourceCode}/>
                </div>
                <div className="project">
                    <ImageTitledCard name="electronVLog" img={content.electronVLog.img} desc={content.electronVLog.desc} theme="projects" tags={content.electronVLog.tags} sourceCode={content.electronVLog.sourceCode}/>
                </div>
                <div className="project">
                    <ImageTitledCard name="writeRight" img={content.writeRight.img} desc={content.writeRight.desc} theme="projects" tags={content.writeRight.tags} sourceCode={content.writeRight.sourceCode}/>
                </div>
                <div className="project">
                    <ImageTitledCard name="khartoum" img={content.khartoum.img} desc={content.khartoum.desc} theme="projects" tags={content.khartoum.tags} sourceCode={content.khartoum.sourceCode}/>
                </div>
                <div className="project">
                    <ImageTitledCard name="khartoum-v2" img={content.khartoumV2.img} desc={content.khartoumV2.desc} theme="projects" tags={content.khartoumV2.tags} sourceCode={content.khartoumV2.sourceCode}/>
                </div>
                <div className="project">
                    <ImageTitledCard name="plusOrMinusOne" img={content.plusOrMinusOne.img} desc={content.plusOrMinusOne.desc} theme="projects" tags={content.plusOrMinusOne.tags} sourceCode={content.plusOrMinusOne.sourceCode}/>
                </div>
                <footer>(And many other tiny contraptions!)</footer>
            </section>
        </div>
    );
}
export default Projects;